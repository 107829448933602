























































































































































































































import Component from 'vue-class-component';
import Vue from 'vue';
import {required} from 'vuelidate/lib/validators'

import RsRadioCard from '@/shared/components/fields/radio-card.vue';
import RsCheckbox from '@/shared/components/fields/checkbox.vue';
import RsCheckboxCard from '@/shared/components/fields/checkbox-card.vue';
import RsSelect from '@/shared/components/fields/select.vue';
import RsInput from '@/shared/components/fields/input.vue';
import RsButton from '@/shared/components/buttons/button.vue';
import RsFile from '@/shared/components/fields/file.vue';
import RsIcon from '@/shared/components/icon/icon.vue';
import {CodetableResult, Codetables} from '@/shared/api/modules/codetable.api.service';
import {API_SERVICE} from '@/shared/api/api.service';
import {debounce} from 'lodash';
import {DATE_SERVICE} from '@/shared/services/date.service';
import {IApiTalentRegistrationRequest} from '@/shared/models/IApiTalentRegistrationRequest';
import {AVAILABILITY} from '@/shared/constants/AVAILABILITY';
import {OnboardingActions} from '@/modules/onboarding/store/actions';
import {OnboardingModule} from '@/modules/onboarding/onboarding.module';
import {Action} from 'vuex-class';
import {IApiTalent} from '@/shared/models/IApiTalent';
import {LOCALSTORAGE} from '@/shared/constants/LOCALSTORAGE';
import {Icons} from '@/shared/components/icon/icons';
import {JobsRouter} from '@/modules/dashboard/modules/jobs/router';
import {JobsActions} from '@/modules/dashboard/modules/jobs/store/actions';
import JobsModule from '@/modules/dashboard/modules/jobs';
import DashboardModule from '@/modules/dashboard';
import {ONBOARDING_STATE} from '@/shared/constants/ONBOARDING_STATE';
import {OnboardingRouter} from '@/modules/onboarding/router';

interface FormOnboarding {
  profile: string | null;
  firstName: string;
  lastName: string;
  location: string;
  country: string;
  timezone: string;
  appliedForJob: boolean;
  jobPosition: string;
  referralSource: string;
  terms: boolean;
}

@Component({
  name: 'rs-onboarding-form-onboarding',
  components: {RsIcon, RsFile, RsButton, RsInput, RsSelect, RsCheckboxCard, RsCheckbox, RsRadioCard},
  validations(){
    const self: any = this;
    return {
      form: {
        firstName: {
          required,
        },
        lastName: {
          required,
        },
        location: {
          required,
        },
        timezone: {
          required,
        },
        jobPosition: {
          minLength: (val) => !self.form.appliedForJob || (val || '').length >= 3
        },
        referralSource: {
          minLength: (val) => !self.form.appliedForJob || (val || '').length >= 3
        },
        terms: {
          checked: (val) => !!val
        },
      }
    }
  },
})
export default class OnboardingFormOnboarding extends Vue {
  @Action(OnboardingActions.SET_USER, {namespace: OnboardingModule.namespace}) setUser: (user: IApiTalent) => void;
  @Action(JobsActions.APPLY_FOR_JOB, {namespace: `${DashboardModule.namespace}/${JobsModule.namespace}`}) applyForJob: (jobId: string) => Promise<void>;

  Icons = Icons;
  env = process.env;

  form: FormOnboarding = {
    profile: null,
    firstName: '',
    lastName: '',
    location: '',
    country: '',
    timezone: '',
    appliedForJob: false,
    jobPosition: '',
    referralSource: '',
    terms: false
  }

  code: string | null = null;

  codetables: CodetableResult = {}

  service: any;
  locationSuggestions: any[] = [];
  locationSuggestionsIndex = -1;

  sending = {
    file: false,
    form: false
  }

  error = ''

  submit() {
    this.error = '';
    this.$v.form.firstName?.$touch();
    this.$v.form.lastName?.$touch();
    this.$v.form.location?.$touch();
    this.$v.form.timezone?.$touch();
    this.$v.form.terms?.$touch();
    this.$v.form.appliedForJob?.$touch();
    if (!this.$v.$invalid) {
      this.sending.form = true;
      this.code = localStorage.getItem(LOCALSTORAGE.REFERRAL);
      const data: IApiTalentRegistrationRequest = {
        properties: {
          TALENT_NAME: this.form.firstName,
          TALENT_LAST_NAME: this.form.lastName,
          TALENT_PROFILE_PICTURE: this.form.profile,
          TALENT_WORK_TIMEZONE: this.form.timezone,
          LOCATION: this.form.location,
          TALENT_APPLICATION_FOR: this.form.appliedForJob ? {
            TALENT_APPLICATION_FOR_POSITION: this.form.jobPosition as string,
            TALENT_APPLICATION_FOR_SITE: this.form.referralSource as string,
          }
            : undefined,
          AVAILABILITY: AVAILABILITY.ACTIVELY_LOOKING
        },
        uiContext: {
          onboardingStatus: ONBOARDING_STATE.ROLES
        },
        referralCode: this.code || undefined,
        country: this.form.country,
      }
      API_SERVICE.auth.registerUser(data)
        .then((res) => {
          this.setUser(res);
          this.$track.sendEvent('OnboardingFinish')
          const jobId = localStorage.getItem(LOCALSTORAGE.JOB_APPLY)
          if (jobId) {
            localStorage.removeItem(LOCALSTORAGE.JOB_APPLY);
            this.applyForJob(jobId)
              .then(() => {
                this.$track.sendEvent('JobApply', {id: jobId})
                this.$router.push({name: JobsRouter.SINGLE, params: {id: jobId}});
              })
              .catch(() => {
                this.$router.push({name: OnboardingRouter.PREFERRED_ROLES});
              })
          } else {
            this.$router.push({name: OnboardingRouter.PREFERRED_ROLES});
          }
        })
        .catch((err) => {
          this.error = err.message;
        })
        .finally(() => {
          this.sending.form = false;
        })
    }
  }

  get getLocationSuggestions() {
    const self = this;
    return debounce(async input => {
      self.locationSuggestions = [];

      if (!input) return;
      if (!self.service) {
        self.service = await new (window as any).google.maps.places.AutocompleteService();
      }

      this.service.getPlacePredictions(
        {
          input,
          types: ['(cities)'],
          language: 'en',
        },
        (suggestions: any[]) => {
          self.locationSuggestions = suggestions || [];
        }
      );
    }, 500)
  }

  selectLocation(location: any) {
    this.form.country = location.terms[location.terms.length - 1].value;
    this.form.location = location.description;
    this.locationSuggestions = [];
    this.form.timezone = `UTC${DATE_SERVICE.getUTCOffset()}`
  }

  selectLocationEnter(){
    if(this.locationSuggestions.length > 0){
      if(this.locationSuggestionsIndex >= 0 && this.locationSuggestionsIndex < this.locationSuggestions.length){
        this.selectLocation(this.locationSuggestions[this.locationSuggestionsIndex]);
      }
      else {
        this.selectLocation(this.locationSuggestions[0]);
      }
    }
  }

  closeAutocomplete(){
    setTimeout(() => {
      this.locationSuggestions = [];
    }, 200)
  }

  async mounted() {
    this.codetables = await API_SERVICE.codetable.getCodetables(Codetables.TIMEZONES);
    this.service = await new (window as any).google.maps.places.AutocompleteService();
    this.code = localStorage.getItem(LOCALSTORAGE.REFERRAL);
  }
}
